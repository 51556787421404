<div class="container">
  <app-spinner *ngIf="loading"></app-spinner>
  <div class=" py-0 config-container">
    <div class="d-flex justify-content-between align-items-center w-100p mb-3 ">
      <div class="title">
        <!-- Configure Your Organization Profile -->
        Welcome {{ userName }} !
      </div>
      <!-- <div class="d-flex">
        <div>
          <button
            (click)="confirmationRequiredmModal()"
            [ngStyle]="{ 'background-color': programConfigform.invalid || !checkChipsSelected ? '#478199' : '#2a647c' }"
            [disabled]="programConfigform.invalid || !checkChipsSelected"
            class="mx-2 submit-button"
          >
            Save and Continue
          </button>
        </div>
      </div> -->
    </div>
    <div class="steps mt-4 pt-2">Step {{ step }}/2</div>
    <span style="font-size: 24px; color: #09325E;">
      {{
        step == 1 ? 'Lets begin by setting up your organization details' : 'Choose frameworks to get started (optional)'
      }}
    </span>
    <form [formGroup]="programConfigform">
      <ng-container *ngIf="step == 1">
        <div>
          <mat-error *ngIf="error?.length"> *{{ error[0]?.toUpperCase() + error?.toLowerCase()?.slice(1) }} </mat-error>
        </div>
        <div novalidate class="p-2">
          <div class="row mt-3">
            <div class="mx-1 input-wrapper">
              <label for="status" class="label">Organization Name <label class="text-danger label">*</label></label>
              <mat-form-field
                class="example-full-width"
                style="height: 38px; font-size: 14px;"
                appearance="outline"
                color="secondary"
              >
                <input matInput placeholder="Type organization name here" formControlName="org_name" />
              </mat-form-field>
              <div class="mt-3" style="height: 14px !important;">
                <ng-container
                  *ngIf="
                    programConfigform.controls.org_name &&
                    (programConfigform.controls.org_name.touched || programConfigform.controls.org_name.dirty)
                  "
                >
                  <mat-error *ngIf="programConfigform.controls.org_name.hasError('required')">
                    *Organization name is required
                  </mat-error>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- <div class="row mt-1">
            <div class="mx-1 input-wrapper">
              <label for="status" class="label">SELECT COMPLIANCE PROGRAM</label>
              <div class="chips-container">
                <ng-container *ngFor="let chip of chips">
                  <div
                    [class.selected]="chip.selected"
                    class=" chip d-flex justify-content-center align-items-center"
                    (click)="onChipClick($event, chip)"
                  >
                    <div class="chiptext">
                      {{ chip.name }}
                    </div>
                    <div *ngIf="chip.name?.toLowerCase() !== 'HIPAA'?.toLowerCase()">
                      <mat-icon *ngIf="chip.selected" class="chip-x">close</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
              <mat-error *ngIf="!checkChipsSelected && !loading">
                *Please select atleast one program
              </mat-error>
            </div>
          </div> -->
        </div>
        <div class="mt-3">
          <button
            (click)="step = 2"
            class="cb-btn-primary continue-btn"
            [ngClass]="{
              'btn-disabled cb-btn-disabled': programConfigform.invalid
            }"
            [disabled]="programConfigform.invalid"
          >
            Continue
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="step == 2">
        <div class="mt-2 step-2-sub">
          Select a framework to kick off your onboarding. You can also skip this step and set up a program later
        </div>
        <div class="row mx-0 mt-1 framework-container">
          <ng-container *ngFor="let item of frameworks">
            <div class="mr-3 mt-3">
              <div
                class="framework-box cb-btn-secondary  position-relative d-flex justify-content-center align-items-center"
                [ngStyle]="{ 'pointer-events': !item.disabled ? 'all' : 'none' }"
                [ngClass]="{ 'framework-selected': item.selected }"
              >
                <div
                  class="mr-2 text-right position-absolute "
                  style="top: 4px;right: 0;"
                  [ngStyle]="{ visibility: !item.disabled ? 'hidden' : 'visible' }"
                >
                  <span class="badge badge-warning p-1 mt-1">Coming soon</span>
                </div>
                <div class="d-flex justify-content-center " [ngClass]="{ faded: item.disabled }">
                  <mat-checkbox
                    *ngIf="!item.disabled"
                    class="mt-2 mr-2"
                    [(ngModel)]="item.selected"
                    [ngModelOptions]="{ standalone: true }"
                    [value]="item.guid"
                  ></mat-checkbox>
                  <div class="d-flex align-items-center">
                    <img class="svg" [src]="'/assets/images/core/' + item.img" />
                    <div class="ml-1">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="mt-5 d-flex">
          <button
            (click)="step = 2"
            class="onboarding-btn cb-btn-primary mr-3"
            [ngClass]="{
              'btn-disabled cb-btn-disabled': programConfigform.invalid || !checkFrameworkSelected
            }"
            [disabled]="programConfigform.invalid || !checkFrameworkSelected"
            (click)="saveForm()"
          >
            Start Onboarding
          </button>
          <button (click)="step = 2" class="cb-btn-secondary onboarding-btn" (click)="skip()">
            Skip for Now
          </button>
        </div>
      </ng-container>
    </form>
    <!-- <div class="d-flex">
      <div class="w-100p mt-4">
        <button
          (click)="saveForm()"
          [ngStyle]="{
            'background-color': programConfigform.invalid || !checkChipsSelected ? 'rgba(0,0,0,.26)' : '#2a647c'
          }"
          [disabled]="programConfigform.invalid || !checkChipsSelected"
          class="submit-button w-100p"
        >
          Setup Profile
        </button>
      </div>
    </div> -->
  </div>
</div>

<div class="dashboard-view">
  <div class="bg-white">
    <div class="py-3 px-4">
      <div class="dashboard-title">
        All Programs
      </div>
    </div>
    <hr class="m-0" />
    <div class="d-flex justify-content-between py-3 px-4">
      <div class="search-inputs">
        <input
          id="search"
          class="form-control cb-input-focus cb-input m-0"
          placeholder="Search"
          name="search"
          type="text"
        />
      </div>
      <div>
        <button class="btn-doc ml-2">
          Onboarding Demo
        </button>
      </div>
    </div>
    <hr class="m-0" />
    <div class="py-3 px-4">
      <div class=" d-inline-block control-chart-container">
        <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/documents/Hippa.svg" /> HIPAA</div>
        <div class="d-flex px-3 pb-3 justify-content-between">
          <div class="control-chart mt-4 ml-2 position-relative">
            <canvas id="Hippa"></canvas>
            <div class="w-100p text-center position-absolute control-summary">
              <div class="control-chart-subtitle">
                PROGRESS
              </div>
              <div class="control-chart-total-percentage" style="font-size: 16px;">
                0%
              </div>
            </div>
          </div>
          <div class="mt-5 ml-1 summary-block">
            <div class="d-flex justify-content-between">
              <span>Policies</span>
              <span>0/0</span>
            </div>
            <div class="progress-policies my-2">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="d-flex justify-content-between">
              <span>Evidence</span>
              <span>0/0</span>
            </div>
            <div class="progress-evidence my-2">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <hr class="m-0 mx-3" />
        <div class="d-flex justify-content-end py-3 pr-2">
          <button class="btn-folder cb-button-primary">
            Add Program
          </button>
        </div>
      </div>
      <div class=" d-inline-block control-chart-container">
        <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/core/SOC2.png" />SOC2</div>
        <div class="d-flex px-3 pb-3 justify-content-between">
          <div class="control-chart mt-4 ml-2 position-relative">
            <canvas id="SOC2"></canvas>
            <div class="w-100p text-center position-absolute control-summary">
              <div class="control-chart-subtitle">
                PROGRESS
              </div>
              <div class="control-chart-total-percentage" style="font-size: 16px;">
                0%
              </div>
            </div>
          </div>
          <div class="mt-5 ml-1 summary-block">
            <div class="d-flex justify-content-between">
              <span>Policies</span>
              <span>0/0</span>
            </div>
            <div class="progress-policies my-2">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="d-flex justify-content-between">
              <span>Evidence</span>
              <span>0/0</span>
            </div>
            <div class="progress-evidence my-2">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <hr class="m-0 mx-3" />
        <div class="d-flex justify-content-end py-3 pr-2">
          <button class="btn-folder cb-button-primary ">
            Add Program
          </button>
        </div>
      </div>
      <div class=" d-inline-block control-chart-container">
        <div class="mt-3 mx-3 control-title"><img src="./../../../assets/images/documents/ISO.svg" /> ISO</div>
        <div class="d-flex px-3 pb-3 justify-content-between">
          <div class="control-chart mt-4 ml-2 position-relative">
            <canvas id="ISO"></canvas>
            <div class="w-100p text-center position-absolute control-summary">
              <div class="control-chart-subtitle">
                PROGRESS
              </div>
              <div class="control-chart-total-percentage" style="font-size: 16px;">
                0%
              </div>
            </div>
          </div>
          <div class="mt-5 ml-1 summary-block">
            <div class="d-flex justify-content-between">
              <span>Policies</span>
              <span>0/0</span>
            </div>
            <div class="progress-policies my-2">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="d-flex justify-content-between">
              <span>Evidence</span>
              <span>0/0</span>
            </div>
            <div class="progress-evidence my-2">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <hr class="m-0 mx-3" />
        <div class="d-flex justify-content-end py-3 pr-2">
          <button class="btn-folder cb-button-primary">
            Add Program
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import Chart from 'chart.js';

@Component({
  selector: 'app-frameworks',
  templateUrl: './frameworks.component.html',
  styleUrls: ['./frameworks.component.scss']
})
export class FrameworksComponent implements OnInit {
  chartManager = {};
  overallSummary: any = {};

  constructor(private orgDashboardService: OrganisationDashboardService) {}

  ngOnInit(): void {
    this.fetchData();
  }
  createDoughnutChart(chartId: string, labels: string[], data: number[], backgroundColor: string[], titleText: string) {
    let options = {
      responsive: true,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      cutoutPercentage: 82,
      maintainAspectRatio: false,
      title: {
        display: false
      }
    };
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    if (this.chartManager[chartId]) {
      this.chartManager[chartId].destroy();
    }
    const ctx = canvas?.getContext('2d');
    var myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderWidth: 1
          }
        ]
      },
      options: options
    });
    this.chartManager[chartId] = myChart;
  }
  getChartData(labels: any, data: any, colors: any) {
    const isEmpty = data.every((value: any) => value === 0);

    if (isEmpty) {
      return {
        labels: ['No Data'],
        data: [1],
        colors: ['#E1E6EB']
      };
    }

    return {
      labels: labels,
      data: data,
      colors: colors
    };
  }
  checkZero(data = {}) {
    return Object.values(data).reduce((accumulator: number, currentValue: number) => {
      return accumulator + currentValue;
    }, 0);
  }

  percentage(percent: number, total: number) {
    if (percent == 0 || total == 0) return 0;
    return ((percent / total) * 100).toFixed(1);
  }

  fetchData() {
    this.orgDashboardService.getFrameworkData().subscribe(
      (res: any) => {
        const hipaaData = res.hipaa;
        const hipaaChartData = this.getChartData(
          ['Policies', 'Evidences'],
          [parseInt(hipaaData.policies, 10), parseInt(hipaaData.evidences, 10)],
          ['#0D9647', '#FA7011']
        );

        this.createDoughnutChart(
          'Hippa',
          hipaaChartData.labels,
          hipaaChartData.data,
          hipaaChartData.colors,
          'HIPAA Progress'
        );

        const soc2Data = res.soc2;
        const soc2ChartData = this.getChartData(
          ['Policies', 'Evidences'],
          [parseInt(soc2Data.policies, 10), parseInt(soc2Data.evidences, 10)],
          ['#0D9647', '#FA7011']
        );
        this.createDoughnutChart(
          'SOC2',
          soc2ChartData.labels,
          soc2ChartData.data,
          soc2ChartData.colors,
          'SOC2 Progress'
        );

        const isoData = res.iso27001;
        const isoChartData = this.getChartData(
          ['Policies', 'Evidences'],
          [parseInt(isoData.policies, 10), parseInt(isoData.evidences, 10)],
          ['#0D9647', '#FA7011']
        );
        this.createDoughnutChart(
          'ISO',
          isoChartData.labels,
          isoChartData.data,
          isoChartData.colors,
          'ISO 27001 Progress'
        );
      },
      error => {
        console.error('Error fetching framework data', error);
      }
    );
  }
}
